
import { defineComponent, ref } from "vue";
import Globals from "@/views/common/Globals.vue";
import ApiService from "@/core/services/ApiService";
import { ElNotification, ElLoading } from "element-plus";
import { hideModal } from "@/core/helpers/dom";
import { eventBus } from "./EventBus";
import { getBreakpoint } from "@/assets/ts/_utils";
export default defineComponent({
  name: "FilterKpiDashBoard",
  mixins: [Globals],

  props: {

  },
  data() {


    const fromDate: Date = new Date()
    fromDate.setHours(0, 0, 0)
    const toDate: Date = new Date()
    const defaultTime2: [Date, Date] = [
      fromDate,
      toDate,

    ]
    return {
      orgList: [],
      org: "",
      selectedOrg: "1SB",
      selectedOrgType: "P",
      selectedApi: "CWA_APPLICATION_STATUS",
      defaultTime2,
      frequency: "hourly",
      apiList: []


    }
  },
  // created() {
  //   eventBus.on('dataCatch', (orgData: any) => {
  //     console.log("Filter with Event Bus created",orgData)
  //   this.selectedApi = orgData.id;
  //   this.selectedOrg = orgData.organizationCode;
  //   this.defaultTime2 = orgData.defaultDate;
  //   this.frequency = orgData.frequency;
  //   })
  // },

  methods: {

    
    //getAPIs
    getApi() {
      ApiService.get(`/organizations/${this.selectedOrg}/apis`).
        then((data) => {
          this.apiList = data.data.data.api_list;
          // console.log("API CALLED IN filter", data)
          //   if( this.apiList.length !=0){
          //     this.selectedApi=this.apiList[0].id;
          //   }
          //   else{
          //     this.selectedApi =" ";
          //   }


        }).catch((error) => {
          console.log("Error inside GetApi", error)
        })
    },
    //cleare Date
    cleareDate() {
      if (this.frequency == "hourly") {
        console.log("date Range", this.defaultTime2)
        // this.dateRange =''
      }

    },
    //Disabled Dates
    dateDisabled(val) {
      if (val > new Date()) {
        return true;
      }

      if (this.frequency === 'daily') {
        const curruntDate = new Date();
        const oneMonthAgo = new Date(curruntDate);
        console.log("Date Before one Month", new Date(oneMonthAgo.setMonth(curruntDate.getMonth() - 1)), val, curruntDate)
        if (val < new Date(oneMonthAgo.setMonth(curruntDate.getMonth() - 1))) {
          console.log("Inside Daily true conditions", val, val < new Date(oneMonthAgo.setMonth(curruntDate.getMonth() - 1)), new Date(oneMonthAgo.setMonth(curruntDate.getMonth() - 1)));
          return true;
        }

      }
    },
    //Apply Filter
    applyFilter() {
      console.log("Date Range In Apply Filter", this.defaultTime2)

      const Date1 = new Date(this.defaultTime2[0]);
      const Date2 = new Date(this.defaultTime2[1]);
      console.log(Date1, Date2, Date2 > Date1, new Date())
      const year1 = new Date(Date1).getFullYear();
      const month1 = String(new Date(Date1).getMonth() + 1).padStart(2, "0");
      const day1 = String(new Date(Date1).getDate()).padStart(2, "0");
      const hour1 = Date1.getHours();
      const year2 = new Date(Date1).getFullYear();
      const month2 = String(new Date(Date1).getMonth() + 1).padStart(2, "0");
      const day2 = String(new Date(Date1).getDate()).padStart(2, "0");
      const hour2 = Date1.getHours();

      let Difference_In_Time = Date1.getTime() - Date2.getTime();
      let Difference_In_hours =Difference_In_Time / (1000 * 3600);


      console.log("🚀 ~ applyFilter ~ DiffinMilisecond:", this.defaultTime2.length,Difference_In_hours)
      if (this.selectedOrg == "" || this.frequency == "" || this.selectedApi == "") {
        ElNotification({
          title: 'Error',
          message: " Please Select Required Filters",
          type: 'error',
          duration: 2000,
          position: 'top-right'
        })
      }
      else if (this.frequency === "hourly" && Math.abs(Difference_In_hours) > 24) {
        ElNotification({
          title: 'Error',
          message: " Please Select 24 Hours time for Hourly",
          type: 'error',
          duration: 2000,
          position: 'top-right'
        })
      }
      else if (Date2 > new Date()) {
        ElNotification({
          title: 'Error',
          message: " Please Select Valid Time",
          type: 'error',
          duration: 2000,
          position: 'top-right'
        })
      }
      else {
        hideModal(this.kpiFilterModalRef);
        this.$emit("setFilter", { dateRange: this.defaultTime2, org: this.selectedOrg, api: this.selectedApi, frequency: this.frequency })
        console.log("Apply Filter", this.defaultTime2, this.org, this.frequency)
      }


    },
    //cleare Filter
    cleareFilter() {

      this.selectedApi = '',
        this.selectedOrg = '',
        this.frequency = ''

    },
    //getApi Provider Method
    getApiProvider() {
      ApiService.get(`/organizations`).then((data) => {
        this.orgList = data.data.data.organization_list;
        console.log("data Inside Tabel Sagar", data)
      }).catch((error) => {
        console.log("Error Inside Organization", error)
      })
    }

  },
  created() {
    this.getApiProvider();
    this.getApi();
    console.log("Filter with Event Bus Created outside");
    eventBus.on('dataCatch', (orgData: any) => {
      console.log("Filter with Event Bus Created", orgData)
      this.selectedApi = orgData.id;
      this.selectedOrg = orgData.organizationCode;
      this.defaultTime2 = orgData.defaultDate;
      this.frequency = orgData.frequency;
      this.applyFilter();
    })
    // eventBus.off('dataCatch');

  },
  setup(){
    const kpiFilterModalRef = ref<null | HTMLElement>(null);
    return{
      kpiFilterModalRef
    }
  }
});
