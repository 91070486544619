
import { defineComponent, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Globals from "@/views/common/Globals.vue";
import ApiService from "@/core/services/ApiService";
import { ElLoading, ElNotification } from "element-plus";
// import { eventBus } from './EventBus'
import alasql from "alasql";
import FilterKpiDashBoard from './KpiFilter.vue'
export default defineComponent({
  name: "HealthDashboard",
  components: {
    FilterKpiDashBoard
  },
  mixins: [Globals],
  data() {
    const fromDate: Date = new Date()
    fromDate.setHours(0, 0, 0)
    const toDate: Date = new Date()
    const defaultTime2: [Date, Date] = [
      fromDate,
      toDate,

    ]

    return {
      chartLatencySeries: [] as any,
      chartLatencyOptions: [] as any,
      chartAvailabilitySeries: [] as any,
      chartAvailabilityOptions: [] as any,
      chartErrorSeries: [] as any,
      chartErrorOptions: [] as any,
      option1: false,
      option2: true,
      orgList: [],
      orgType: [],
      selectedOrg: "1SB",
      selectedOrgType: "P",
      selectedApi: "CWA_APPLICATION_STATUS",
      defaultTime2,
      fromDate,
      toDate,
      frequency: "hourly",
      apiList: [],
      boundryValues: {} as any,
      averageValues: {} as any,
      errors: [] as any,
      availability: [] as any,
      latency: [] as any,
      show:false
    }
  },
  methods: {
    //Date Convertor
    dateConverterForChart(val) {
      const year = new Date(val).getFullYear();
      const month = String(new Date(val).getMonth() + 1).padStart(2, "0");
      const day = String(new Date(val).getDate()).padStart(2, "0");
      var monthList = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ];

      return `${day} ${monthList[new Date(val).getMonth()]} ${year}`;
    },
    //Show Method
    showMethod()
    {
      this.show = !this.show
    },
    //setFilter
    setFilter(val) {
      this.defaultTime2=val.dateRange,
      this.selectedOrg = val.org,
      this.selectedApi = val.api,
        this.fromDate = val.dateRange[0],
        this.toDate = val.dateRange[1],
        this.frequency = val.frequency;
      this.getApis();
    },
    toggleSwitch(option) {
      if (option == "Option1") {
        this.option1 = true;
        this.option2 = false;
      } else {
        this.option2 = true;
        this.option1 = false;
      }
    },
    //Date Convertor
    dateConverter(val) {
      const year = new Date(val).getFullYear();
      const month = String(new Date(val).getMonth() + 1).padStart(2, "0");
      const day = String(new Date(val).getDate()).padStart(2, "0");
      const hour = new Date(val).getHours();
      const minute = new Date(val).getMinutes();
      const second = new Date(val).getSeconds();
      return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
    },
    //DateTime Convertor
    dateTimeConvertor(val) {
      const year = new Date(val.split("T")[0]).getFullYear();
      const month = String(new Date(val.split("T")[0]).getMonth() + 1).padStart(
        2,
        "0"
      );
      const day = String(new Date(val.split("T")[0]).getDate()).padStart(
        2,
        "0"
      );
      var monthList = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ];

      return `${day} ${
        monthList[new Date(val.split("T")[0]).getMonth()]
      } ${year} ${val.split("T")[1]}:00:00`;
    },
    //get Oraganization
    getOrganizations() {

      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      ApiService.get(
        `/organizations`
      )
        .then((data) => {

          loading.close();
          this.orgList = data.data.data.organization_list
        })
        .catch((error) => {
          loading.close();

        });
    },
    // ApiService.get(
    //     `/organizations/${this.org}/apis?pageNumber=${this.page}&pageSize=${this.rowPerPage}&fromDate=${this.fromDate}&toDate=${this.toDate}&frequency=${this.frequency}`
    //   )
    getApis() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      ApiService.get(`/organizations/${this.selectedOrg}/apis?apiId=${this.selectedApi}&fromDate=${this.dateConverter(this.fromDate)}&toDate=${this.dateConverter(this.toDate)}&frequency=${this.frequency}`)
        .then((data) => {
          this.apiList = data.data.data.api_list;
          // const result = alasql(
          //     "SELECT AVG(errorPercentage) as ErrorPercentage , AVG(averageLatency) as AverageLatency , AVG(availabilityPercentage) as AvailabilityPercentage  FROM ?",
          //     [data.data.data.api_list]
          //   );
          let Result3 = alasql("SELECT * FROM ? WHERE id==?", [data.data.data.api_list, this.selectedApi]);
          const result = alasql(
            "SELECT  GROUP_CONCAT(errorPercentage) AS ErrorPercentage1 ,GROUP_CONCAT(averageLatency) AS AverageLatency1,GROUP_CONCAT(availabilityPercentage) AS AvailabilityPercentage1, GROUP_CONCAT(CONCAT(year,'/',month,'/',day)) AS ForDay,GROUP_CONCAT(CONCAT(year,'/',month,'/',day,'T',hour)) AS Hour,AVG(errorPercentage) as ErrorPercentage , AVG(averageLatency) as AverageLatency , AVG(availabilityPercentage) as AvailabilityPercentage  FROM ?",
            [this.apiList]
          );
          const result2 = alasql(
            `SELECT *  FROM ? WHERE id = ${this.selectedApi}`,
            [data.data.data.api_list]
          );

          this.averageValues = result[0];
          this.boundryValues = data.data.data.standardKPI;
          const availability = this.averageValues.AvailabilityPercentage1.split(',').map((item) => item === "null" ? 0 : parseFloat(item));
          const latency = this.averageValues.AverageLatency1.split(',').map((item) => item === "null" ? 0 : parseFloat(item));
          const error = this.averageValues.ErrorPercentage1.split(',').map((item) => item === "null" ? 0 : parseFloat(item));
          const DaysUpdated = this.averageValues.ForDay.split(',').reverse().map((item)=>this.dateConverterForChart(item))
          const HoursUpdated = this.averageValues.Hour.split(',').reverse().map((item)=>this.dateTimeConvertor(item))
          this.chartLatencySeries = [{
            name: "Avilability",
            data: latency
          }],
            this.chartLatencyOptions = {
              chart: {
                type: 'bar',
                stacked: true,
                zoom: {
                  enabled: true
                },  scroller:{
                  enabled:true
                },
                scrollX:{
                  enabled:true
                },
              },
           
              plotOptions: {
                bar: {
                  horizontal: true,
                  barHeight: '70%',

                },
              },
              stroke: {
                width: 1,
                colors: ['#fff']
              },

              xaxis: {

                categories: this.frequency === "hourly" ? HoursUpdated :DaysUpdated,
              },
            
              tooltip: {
                y: {
                  formatter: function (val) {
                    return val + "ms"
                  }
                }
              },
              fill: {
                opacity: 1

              },
              legend: {
                position: 'bottom',
                horizontalAlign: 'left',
                offsetX: 40
              }
            };
          this.chartErrorSeries = [{
            name: "Error",
            data: error
          }],
            this.chartErrorOptions = {
              chart: {
                type: 'bar',
                zoom: {
                  enabled: true
                }

              },
              dataLabels: {
                enabled: true, // Enable data labels
                formatter: function (value) {
                  return value.toFixed(2); // Format to two decimal places
                },
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                  barHeight: '70%',

                },
              },
              stroke: {
                width: 1,
                colors: ['#fff']
              },
              yaxis: {
                min: 0,
                max: 100,
                type: 'numeric'
              },
              xaxis: {

                categories: this.frequency === "hourly" ? HoursUpdated : DaysUpdated,
              },

              tooltip: {
                y: {
                  formatter: function (val) {
                    return val.toFixed(2) + "%"
                  }
                }
              },
              fill: {
                opacity: 1

              },
              legend: {
                position: 'bottom',
                horizontalAlign: 'left',
                offsetX: 40
              }
            };
          this.chartAvailabilityOptions = {
            chart: {
              type: 'bar',
              zoom: {
                enabled: true
              }, 
              

            },
            dataLabels: {
                enabled: true, // Enable data labels
                formatter: function (value) {
                  return value.toFixed(2); // Format to two decimal places
                },
              },
            plotOptions: {
              bar: {
                horizontal: true,
                barHeight: '70%',
              },
            },
            stroke: {
              width: 1,
              colors: ['#fff']
            },
            scrollbar:{
                  enabled:true
                },
            yaxis: {
              min: 0,
              max: 100,
              scrollbar: {
                        enabled: true
                    },
              type: 'numeric'
            },
            xaxis: {
              categories: this.frequency === "hourly" ? HoursUpdated : DaysUpdated,
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return val.toFixed(2) + "%"
                }
              }
            },
            fill: {
              opacity: 1

            },
            legend: {
              position: 'bottom',
              horizontalAlign: 'left',
              offsetX: 40
            }
          };
          this.chartAvailabilitySeries = [{
            name: "Availability",
            data: availability,
            lineWidth: 50
          }],
            loading.close();
        })
        .catch((error) => {
          loading.close();
          ElNotification({
            title: "Error",
            message: JSON.stringify(
              error.response.data["errors"][0]["errorMessage"]
            ),
            type: "error",
            duration: 2000,
            position: "top-right",
          });

          console.log("Error In API List", error)
        })
    }
  },
  // created() {
  //   console.log("before create Component")
  //   eventBus.('dataCatch', (orgData: any) => {
  //     console.log("DATA in Destination KPI component", orgData)
  //     this.selectedApi = orgData.id;
  //     this.selectedOrg = orgData.organizationCode;
  //     this.defaultTime2 = orgData.defaultDate;
  //     this.boundryValues = orgData.bounderyValus;
  //     this.getOrganizations();
  //   this.getApis();
  //     console.log("DATA in Destination KPI component", orgData,  this.selectedApi,this.selectedOrg, this.defaultTime2,this.boundryValues )
  //   })
  
   
  // },
  mounted() {
    this.getOrganizations();
    this.getApis();    
    // eventBus.on('dataCatch', (orgData: any) => {
    //   console.log("DATA in Destination KPI component", orgData)
    //   this.selectedApi = orgData.id;
    //   this.selectedOrg = orgData.organizationCode;
    //   this.defaultTime2 = orgData.defaultDate;
    //   this.boundryValues = orgData.bounderyValus;
    //   this.getOrganizations();
    //   this.getApis();
    //   console.log("DATA in Destination KPI component", orgData,  this.selectedApi,this.selectedOrg, this.defaultTime2,this.boundryValues )
    // })
  },
  setup() {
  onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Service KPI Dashboard", ["API Insight"]);
    });

  },
});
